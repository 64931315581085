<template>
  <div class="Awarp">
    <dashCard
      class="photovoltaicBox11"
      :operateWidth="operateWidth"
      :isLoading="isLoading"
      :gridOption="gridOption"
      @changeScreen="changeScreen"
    >
      <template slot="title">{{commonLangHandler('photovoltaicBox11_runtimeData','光伏逆变器实时数据', getZEdata)}}</template>
      <template slot="aside">
        <a-select
          v-model="searchObj['ITEM']"
          style="width: 220px"
          @change="handleChange"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        >
          <a-select-option
            v-for="(item, key) in eqlist"
            :key="key"
            :value="item.assetNumber"
          >
            {{ item.assetName }}
          </a-select-option>
        </a-select>
      </template>
      <div class="content-warp">
        <div class="content-1st">
          <div class="product-pre1">
            <div style="width: 160px">
              <img :src="detailInfo.src" />
            </div>
          </div>
          <div class="content-1st-right">
            <div class="content-1st-right-row">
              <div>
                <font>{{commonLangHandler('photovoltaicBox11_working','工作状态', getZEdata)}}</font>
                <span class="color1" style="font-size: 32px">{{
                  detailInfo.value1
                }}</span>
              </div>
              <div>
                <font>{{commonLangHandler('photovoltaicBox11_network','入网状态', getZEdata)}}</font>
                <span style="font-size: 32px">{{ detailInfo.value2 }}</span>
              </div>
            </div>
            <div class="content-1st-right-row">
              <div style="word-break: break-all">
                <font>{{commonLangHandler('photovoltaicBox11_totalRuntime','总运行时间', getZEdata)}}</font>
                <span class="color2" style="font-size: 30px"
                  >{{ toThousandsSeparator(parseFloat(detailInfo.value3).toFixed(2))
                  }}<font>h</font></span
                >
              </div>
              <div style="word-break: break-all">
                <font>{{commonLangHandler('photovoltaicBox11_totalpowerGeneration','总发电量', getZEdata)}}</font>
                <span style="font-size: 30px"
                  >{{ toThousandsSeparator(parseFloat(detailInfo.value4).toFixed(2))
                  }}<font>kWh</font></span
                >
              </div>
            </div>
          </div>
        </div>
        <div class="content-2nd">
          <div class="content-2nd-list">
            <div
              class="content-2nd-list-item"
              v-for="(item, idx) in detailInfo.list1"
              :key="idx"
            >
              <span class="list-item-label">{{ item.name }}</span>
              <div class="list-item-progress" style="width: 100px">
                <span
                  :class="'status' + item.status"
                  :style="{
                    width: perceCalc(item.min,item.count,item.num) + '%'
                  }"
                ></span>
              </div>
              <span class="list-item-value">{{ item.num.toFixed(2) }}</span>
            </div>
          </div>
          <div class="content-2nd-list">
            <div
              class="content-2nd-list-item"
              v-for="(item, idx) in detailInfo.list2"
              :key="idx"
            >
              <span class="list-item-label">{{ item.name }}</span>
              <div class="list-item-progress" style="width: 100px">
                <span
                  :class="'status' + item.status"
                  :style="{
                    width: perceCalc(item.min,item.count,item.num) + '%'
                  }"
                ></span>
              </div>
              <span class="list-item-value">{{ item.num.toFixed(2) }}</span>
            </div>
          </div>
        </div>
      </div>
    </dashCard>
    <a-modal
      v-if="!isModal"
      class="Amodal"
      v-model="option.visible"
      :closable="false"
      :footer="null"
      :keyboard="false"
      width="100%"
      :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <photovoltaicBox11
          :option="option"
          :isModal="true"
          :orginSearchObj="searchObj"
        />
      </div>
    </a-modal>
  </div>
</template>
    <script>
// E:\ABB项目\lxh-applications-front\src\assets\imgs\echarts\光伏逆变器.jpg
import Img005 from "@/assets/imgs/echarts/光伏逆变器.svg";
export default {
  name: "photovoltaicBox11",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo.CODE": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getList();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中

      itemList: [
        {
          code: "逆变器编号：1AA1",
          name: "逆变器编号：1AA1",
        },
      ],
      searchObj: {
        ITEM: "", //选中的项目
      },
      chartOption1: {},
      detailInfo: {},
      colorList: ["#1191E6", "#7ED6C6", "#1E6119", "#160C9C"],
      eqlist: [],
    };
  },
  computed: {
    // 当前Dept信息
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
  },
  methods: {
    perceCalc(min,max,num){
      let perce = 0;
      if(isNaN(min)||isNaN(max)||isNaN(num)){
              perce = 0
            }else{
              if((Number(max)-Number(min)) == 0 || (Number(num)-Number(min)) == 0){
                perce = 0
              }else{
                perce = (Number(num)-Number(min))/(Number(max)-Number(min))
              }
            }
      if(perce < 0 || perce == 0){
        return 0
      }else if(perce > 1 || perce == 1){
        return 100
      }else{
        return perce*100
      }
    },
    getList() {
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/equipment/assetlist",
        method: "post",
        data: {
          deptCode: this.currentSelectDeptInfo.CODE,
          type:"光伏逆变器"
        },
      }).then((res) => {
        if(!res.data.data){
          this.eqlist = []
        }else{
          this.eqlist = res.data.data;
          if (this.eqlist.length > 0) {
            this.searchObj.ITEM = this.eqlist[0].assetNumber;
          }
        }
        
        this.getData();
      });
    },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var monthVALUE = this.$moment(dateStr).startOf("month");
      var obj = {
        按天: {
          type: "今日",
          startDate: VALUE.format("YYYY-MM-DD 00:00:00"),
          endDate: VALUE.format("YYYY-MM-DD 23:59:59"),
        },
        按周: {
          type: "本周",
          startDate: VALUE.startOf("week").format("YYYY-MM-DD 00:00:00"),
          endDate: VALUE.endOf("week").format("YYYY-MM-DD 23:59:59"),
        },
        按月: {
          type: "本月",
          startDate: monthVALUE.startOf("month").format("YYYY-MM-DD 00:00:00"),
          endDate: monthVALUE.endOf("month").format("YYYY-MM-DD 23:59:59"),
        },
        按年: {
          type: "全年",
          startDate: VALUE.startOf("year").format("YYYY-MM-DD 00:00:00"),
          endDate: VALUE.endOf("year").format("YYYY-MM-DD 23:59:59"),
        },
      };
      return obj[TYPE];
    },
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },
    // 数字转为千位分隔符表示
    toThousandsSeparator: function (value) {
      if (!value) return 0;
      // 获取整数部分
      const intPart = Math.trunc(value);
      // 整数部分处理，增加,
      const intPartFormat = intPart
        .toString()
        .replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
      // 预定义小数部分
      let floatPart = "";
      // 将数值截取为小数部分和整数部分
      const valueArray = value.toString().split(".");
      if (valueArray.length === 2) {
        // 有小数部分
        floatPart = valueArray[1].toString(); // 取得小数部分
        return intPartFormat + "." + floatPart;
      }
      return intPartFormat + floatPart;
    },

    // 切换项目
    handleChange(value) {
      this.getData();
    },
    //每月报警预警数
    getData() {
      // if(this.searchObj.ITEM==""){
      //   this.detailInfo = {
      //     src: Img005,
      //     value1: "",
      //     value2:"", 
      //     value3:"",
      //     value4:"",
      //     list1: []
      //   }
      //   return
      // }
      this.isLoading = true;
      this.$axios({
        url: Config.dev_url + "/api-apps-v2/api/v2/photovoltaic/inverter/realtimedata",
        method: "post",
        data: {
          assetNumber: this.searchObj.ITEM,
        },
      })
        .then((res) => {
          let result = res.data.data;
          let obj = {
            工作状态: "",
            入网状态: "",
            总运行时间: "",
            总发电量: "",
          };
          //console.log(result);
          let gzIndex = result.findIndex((item) => {
            return item.name === "系统运行状态";
          });
          if (gzIndex !== -1) {
            obj["工作状态"] = result[gzIndex].num;
            result.splice(gzIndex, 1);
          }
          let rwIndex = result.findIndex((item) => {
            return item.name === "系统入网状态";
          });
          if (rwIndex !== -1) {
            obj["入网状态"] = result[rwIndex].num;
            result.splice(rwIndex, 1);
          }
          let jnIndex = result.findIndex((item) => {
            return item.name === "发电总时间";
          });
          if (jnIndex !== -1) {
            obj["总运行时间"] = result[jnIndex].num;
            result.splice(jnIndex, 1);
          }
          let drIndex = result.findIndex((item) => {
            return item.name === "总并网发电量";
          });
          if (drIndex !== -1) {
            obj["总发电量"] = result[drIndex].num;
            result.splice(drIndex, 1);
          }
          // //console.log(result)

          // this.detailInfo = {
          //   src: Img005,
          //   value1: obj["工作状态"],
          //   value2:obj["入网状态"],
          //   value3:obj["机内空气温度"],
          //   value4:obj["当日发电量"],
          //   list1: result
          // }
          let arr = [
            {
              name: this.LangHandler('photova11outA','输出A相电流'),
              status: 1,
              count: 0,
              num: 0,
              min: 0
            },
            {
              name: this.LangHandler('photova11outB','输出B相电流'),
              status: 1,
              count: 0,
              num: 0,
              min: 0
            },
            {
              name: this.LangHandler('photova11outC','输出C相电流'),
              status: 1,
              count: 0,
              num: 0,
              min: 0
            },
            {
              name: this.LangHandler('photova11rpower','无功功率'),
              status: 1,
              count: 0,
              num: 0,
              min: 0
            },
            {
              name: this.LangHandler('photova11frequency','电网频率'),
              status: 1,
              count: 0,
              num: 0,
              min: 0
            },
            {
              name: this.LangHandler('photova11userate','功率利用率'),
              status: 1,
              count: 0,
              num: 0,
              min: 0
            },
            {
              name: this.LangHandler('photova11outVA','输出A相电压'),
              status: 1,
              count: 0,
              num: 0,
              min: 0
            },
            {
              name: this.LangHandler('photova11outVB','输出B相电压'),
              status: 1,
              count: 0,
              num: 0,
              min: 0
            },
            {
              name: this.LangHandler('photova11outVC','输出C相电压'),
              status: 1,
              count: 0,
              num: 0,
              min: 0
            },
            {
              name: this.LangHandler('photova11apower','有功功率'),
              status: 1,
              count: 0,
              num: 0,
              min: 0
            },
            {
              name: this.LangHandler('photova11rtemp','散热器温度'),
              status: 1,
              count: 0,
              num: 0,
              min: 0
            },
            {
              name: this.LangHandler('photova11etemp','设备温度'),
              status: 1,
              count: 0,
              num: 0,
              min: 0
            },
          ];

          result.forEach((item) => {
            arr.forEach((item1) => {
              if (item1.name === item.name) {
                //console.log(item);
                //console.log(item1);
                if (
                  item1.name === "输出A相电流" ||
                  item1.name === "输出B相电流" ||
                  item1.name === "输出C相电流"
                ) {
                  item1.name += "(A)";
                } else if (
                  item1.name === "输出A相电压" ||
                  item1.name === "输出B相电压" ||
                  item1.name === "输出C相电压"
                ) {
                  item1.name += "(V)";
                } else if (
                  item1.name === "有功功率" ||
                  item1.name === "无功功率"
                ) {
                  item1.name += "(kW)";
                } else if (item1.name === "电网频率") {
                  item1.name += "(Hz)";
                } else if (item1.name === "功率利用率") {
                  item1.name += "(%)";
                } else if (
                  item1.name === "设备温度" ||
                  item1.name === "散热器温度"
                ) {
                  item1.name += "(℃)";
                }
                if (item1.name === "功率利用率(%)") {
                  item1.count = 120;
                } else {
                  item1.count = Number(item.count);
                }

                item1.num = Number(item.num);
                // item1.status = item.status
              }
            });
          });
          //console.log(arr);
          //console.log(obj);
          this.detailInfo = {
            src: Img005,
            value1: obj.工作状态,
            value2: obj.入网状态,
            value3: obj["总运行时间"],
            value4: obj["总发电量"],
            list1: [...arr.slice(0, 6)],
            list2: [...arr.slice(6, -1)],
          };
        })
        .catch((wrong) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    getData0() {
      this.detailInfo = {
        src: Img005,
        value1: "运行",
        value2: "并网",
        value3: 44,
        value4: 102,
        list1: [
          {
            name: "A相电流(A)",
            status: 1,
            count: 30,
            num: 20,
            min: 0
          },
          {
            name: "B相电流(A)",
            status: 3,
            count: 30,
            num: 20,
            min: 0
          },
          {
            name: "C相电流(A)",
            status: 3,
            count: 30,
            num: 20,
            min: 0
          },
          {
            name: "额定输出功率(kW)",
            status: 3,
            count: 20,
            num: 10,
            min: 0
          },
          {
            name: "电网频率(Hz)",
            status: 2,
            count: 100,
            num: 50,
            min: 0
          },
          {
            name: "设备利用率(%)",
            status: 2,
            count: 30,
            num: 20,
            min: 0
          },
          {
            name: "总并网运行时间(h)",
            status: 1,
            count: 1500,
            num: 780,
            min: 0
          },
        ],
        list2: [
          {
            name: "A相电压(V)",
            status: 1,
            count: 800,
            num: 500,
            min: 0
          },
          {
            name: "B相电压(V)",
            status: 1,
            count: 800,
            num: 500,
            min: 0
          },
          {
            name: "C相电压(V)",
            status: 1,
            count: 800,
            num: 500,
            min: 0
          },
          {
            name: "有功功率(kW)",
            status: 1,
            count: 800,
            num: 500,
            min: 0
          },
          {
            name: "等价发电时间(h)",
            status: 1,
            count: 3000,
            num: 2618,
            min: 0
          },
          {
            name: "逆变器效率(%)",
            status: 1,
            count: 100,
            num: 91,
            min: 0
          },
          {
            name: "累计发电量(kWh)",
            status: 1,
            count: 2000,
            num: 1780,
            min: 0
          },
        ],
      };
    },
  },
  mounted() {
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getList();
    }
  },
};
</script>

<style lang="less" scoped>
.photovoltaicBox11 {
  .content-warp {
    display: flex;
    height: inherit;
    flex-direction: column;
    .content-1st {
      display: flex;
      height: 279px;
      justify-content: space-between;
      .product-pre1 {
        width: 170px;
        height: inherit;
        margin-right: 70px;

        margin-top: 20px;
        display: flex;
        justify-content: center;
        img {
          width: 100%;
          height: auto;
          margin-top: 20%;
        }
      }
      .content-1st-right {
        flex: 1;
        display: flex;
        margin-top: 20px;
        flex-direction: column;
        .content-1st-right-row {
          display: flex;
          justify-content: flex-start;
          &:nth-child(1) {
            margin-bottom: 50px;
          }
          div {
            display: flex;
            flex-direction: column;
            width: 215px;
            height: 78px;
            font-size: 16px;
            font-weight: 400;
            color: #595959;
            line-height: 24px;
            span {
              margin-top: 6px;
              font-size: 28px;
              font-weight: 500;
              color: #1f1f1f;
              line-height: 48px;
              font {
                font-size: 12px;
                font-weight: 400;
                color: #9f9f9f;
                line-height: 20px;
                margin-left: 6px;
              }
            }
          }
          .color1 {
            color: #3366ff;
          }
          .color2 {
            color: #51a34b;
          }
        }
      }
    }
    .content-2nd {
      display: flex;
      justify-content: flex-start;
      .content-2nd-list {
        flex: 1;
        .content-2nd-list-item {
          height: 40px;
          box-sizing: border-box;
          padding: 10px 12px;
          display: flex;
          align-items: center;
          .list-item-label {
            width: 105px;
            font-size: 14px;
            font-weight: 400;
            color: #1f1f1f;
            line-height: 20px;
          }
          .list-item-progress {
            margin-right: 28px;
            //flex: 1;
            height: 7px;
            background: #dbdbdb;
            span {
              display: block;
              height: inherit;
              width: 0%;
            }
          }
          .list-item-value {
            font-size: 14px;
            font-weight: 400;
            color: #1f1f1f;
            line-height: 20px;
          }
        }
        .status1 {
          background: #25b131;
        }
        .status2 {
          background: #ff7b10;
        }
        .status3 {
          background: #f1404f;
        }
      }
    }
  }
}
</style>
